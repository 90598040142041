import React from "react";
import aboutUsImage from "../assets/about-us-image.png";

const About = () => {
  return (
    <div className="grid justify-items-center container mx-auto  h-full text-white">
      <div className="grid content-start justify-items-center pt-16 mt-16 z-1">
        <h1 className="text-7xl font-displaySemibold mb-6 mt-10">Our Story</h1>
        <p className="text-xl w-[50%] text-center">
          In 2023, <span className="f font-displaySemibold">NewUrban </span>
          emerged as a platform dedicated to empowering property developers with
          management and tracking of customer instalments, while also offering a
          Feed to post new properties <br /> for potential clients.
        </p>
        <div className="p-10 ">
          <img
            src={aboutUsImage}
            alt="about-us"
            className="h-[320px] border-backgroundblue border-8 ring-8 ring-backgroundblue rounded-2xl"
          ></img>
        </div>
      </div>

      <div className="grid grid-cols-2 content-start justify-items-center m-16 pt-6 pb-16">
        <div className="grid justify-items-center content-start pt-10">
          <h1 className="text-6xl font-displaySemibold text-center mb-2">
            Contact us
          </h1>
          <p className="text-xl w-[80%] text-center font-displayRegular">
            Need to get in touch with us? Either fill <br /> in the form with
            your inquiry or email us at{" "}
            <span className="t text-btnorange font-displayMid">
              newurabn.realestate@example.com
            </span>
            .
          </p>
        </div>
        <div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

const ContactForm = () => {
  return (
    <div className="bg-white p-8 border-backgroundblue border-8 rounded-2xl font-displayRegular mx-auto max-w-md">
      <div className="flex mb-4">
        <div className="w-1/2 mr-2">
          <label
            htmlFor="first-name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            First Name
          </label>
          <input
            type="text"
            id="first-name"
            className="w-full px-3 py-2 bg-gray-300 text-sm  text-black rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter your first name"
          />
        </div>
        <div className="w-1/2 ml-2">
          <label
            htmlFor="last-name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Last Name
          </label>
          <input
            type="text"
            id="last-name"
            className="w-full px-3 py-2 bg-gray-300 text-sm text-black rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Enter your last name"
          />
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="work-email"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Work Email
        </label>
        <input
          type="email"
          id="work-email"
          className="w-full px-3 py-2 bg-gray-300 text-sm  text-black  rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          placeholder="Enter your work email"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="message"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Message
        </label>
        <textarea
          id="message"
          className="w-full px-3 py-2 bg-gray-300 text-sm  text-black resize-none  rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          rows="8"
          placeholder="Type your message here"
        ></textarea>
      </div>
      <div className="grid justify-items-center">
        <button class="relative flex h-[50px] w-40 items-center justify-center overflow-hidden bg-btnorange   rounded-md font-displaySemibold text-white shadow-lg transition-all duration-300 before:absolute before:inset-0 before:border-0 before:border-white before:duration-100 before:ease-linear hover:bg-white hover:text-blue-600 hover:shadow-blue-200 hover:border-2 hover:border-blue-600 hover:before:border-[25px]">
          <span class="relative z-10">Submit</span>
        </button>
      </div>
    </div>
  );
};

export default About;

{
  /* <div className="grid justify-items-center content-center">
        <button className="bg-blue-500 border-2 border-blue-500  text-white font-bold py-2 px-4 rounded transition-all duration-300 before:absolute before:duration-100 before:ease-linear hover:bg-white hover:text-btnorange hover:border-btnorange">
          Submit
        </button>
      </div> */
}
