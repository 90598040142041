import React from "react";
import phonemockup from "../assets/phone-mockup.png";
import TrackVisibility from "react-on-screen";
import { Link } from "react-router-dom";
import appname from "../assets/appname.png";
import branding from "../assets/branding.png";
import appfeed from "../assets/appfeed.png";
import appchat from "../assets/appchat.png";
import myProjectsImage from "../assets/myprojects.png";
import CarouselScroll from "./SubComp/ImageCarousel";

const Home = () => {
  const images = [
    "https://images.pexels.com/photos/169647/pexels-photo-169647.jpeg?auto=compress&cs=tinysrgb&w=600",
    "https://images.pexels.com/photos/313782/pexels-photo-313782.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/773471/pexels-photo-773471.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/672532/pexels-photo-672532.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/632522/pexels-photo-632522.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    "https://images.pexels.com/photos/777059/pexels-photo-777059.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  ];

  return (
    <div className="grid justify-items-center">
      <div className="container grid grid-cols-3 lg:px-[60px]  xl:px-[100px] pt-24 mt-6 z-1 content-center justify-items-center">
        <div className="grid text-white content-center col-span-2 ">
          <span className=" font-displaySemibold lg:text-7xl xl:text-8xl leading-[80px]">
            Get your own real estate App
          </span>
          <p className="font-displayRegular py-4 lg:text-xl xl:text-2xl leading-[30px] w-[80%]">
            Boost your business with our white labeled app, almost ready to go
            live with very little customization.
          </p>
        </div>
        <div className="grid  content-center justify-items-center rotate-phone">
          <img
            src={phonemockup}
            alt="logo"
            className="lg:w-[230px] lg:h-[480px] xl:w-[260px] xl:h-[520px]  drop-shadow-2xl   animate-bouncy"
            unselectable="on"
          />
        </div>
      </div>
      <div className="m m-4">
        <Link
          to="/request"
          class="relative flex lg:h-[60px] lg:w-[240px] xl:h-[72px] xl:w-[280px] items-center justify-center overflow-hidden bg-btnorange   rounded-lg font-displaySemibold text-white shadow-2xl transition-all duration-300 before:absolute before:inset-0 before:border-0 before:border-white before:duration-100 before:ease-linear hover:bg-white hover:text-blue-600 hover:shadow-blue-600 hover:before:border-[25px]"
        >
          <span class="relative z-1 lg:text-lg xl:text-xl">Request a demo</span>
        </Link>
      </div>

      <div className="px-[170px] ">
        <TrackVisibility>
          <SectionTitle title="What can you do with NewUrban?" />
        </TrackVisibility>
      </div>
      <div className="  p pt-2 px-[100px]">
        <CarouselScroll />
      </div>

      <TrackVisibility>
        <SectionTitle title="Discover the benefits." />
      </TrackVisibility>
      {/* APP NAME AND LOGO */}
      <div className="w-[95%]">
        <TrackVisibility>
          <AppNameAndLogo />
        </TrackVisibility>
      </div>
      {/* BRANDING */}
      <div className="w-[95%]">
        <TrackVisibility>
          <Branding />
        </TrackVisibility>
      </div>
      {/* FEED */}
      <div className="w-[95%]">
        <TrackVisibility>
          <Feed />
        </TrackVisibility>
      </div>
      {/* INVESTMENTS */}
      <div className="w-[95%]">
        <TrackVisibility>
          <Investments />
        </TrackVisibility>
      </div>
      {/* IN APP CHAT */}
      <div className="w-[95%]">
        <TrackVisibility>
          <InAppChat />
        </TrackVisibility>
      </div>
      <div className="grid grid-rows-2 content-center justify-items-center w-[90%] my-10 p-20 py-48 bg-backgroundblue rounded-full border border-white border-double">
        <div className="grid content-center justify-items-center">
          <h1 className=" text-white text-6xl font-displaySemibold">
            Want to explore more?
          </h1>
          <p className="text-white font-displayRegular text-xl p-4">
            See our platform in action
          </p>
        </div>
        <div className="m m-4">
          <Link
            to="/request"
            class="relative flex h-[60px] w-[240px] items-center justify-center overflow-hidden bg-btnorange   rounded-lg font-displaySemibold text-white shadow-2xl transition-all duration-300 before:absolute before:inset-0 before:border-0 before:border-white before:duration-100 before:ease-linear hover:bg-white hover:text-blue-600 hover:shadow-blue-600 hover:before:border-[25px]"
          >
            <span class="relative z-1">Request a demo</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Home;

const SectionTitle = (props) => {
  const style = {
    opacity: props.isVisible ? "1" : "0.5",
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <div style={style} className="pt-24 pb-10">
      <h1 className="text-7xl font-displayBold text-white">{props.title}</h1>
    </div>
  );
};

const AppNameAndLogo = (props) => {
  const style = {
    opacity: props.isVisible ? "1" : "0.6",
    transition: "opacity 0.3s ease-in-out",
  };

  return (
    <div
      style={style}
      className="bg-blue-200 h-fit  rounded-br-full  rounded-tr-full rounded-bl-full mb-16 bg-opacity-30"
    >
      <div className="grid grid-cols-3 h-full content-center">
        <div className="grid col-span-2 justify-items-center content-center ">
          <h1 className=" pt-8 w-fit pr-4  text-white font-displaySemibold text-5xl ">
            Craft Your Signature <br />{" "}
            <span className=" text-2xl italic">Your own App Name & Logo </span>
          </h1>
          <div className="grid justify-items-center w-[60%] ml-[6%]">
            <p className=" pt-4 text-wrap text-white font-displayRegular text-2xl">
              Unleash your unique brand identity with custom app name & logo
              design. We expertly develop your app and bring it to life on Apple
              App Store & Google Play Store.
            </p>
          </div>
        </div>
        <div className=" col-span-1  bg-blue-200 bg-opacity-10 translate-x-5 rounded-full">
          <img
            src={appname}
            alt="app name and logo"
            className=" w-[80%] h-[100%] translate-x-[-25%]"
          ></img>
        </div>
      </div>
    </div>
  );
};

const Branding = (props) => {
  const style = {
    opacity: props.isVisible ? "1" : "0.6",
    transition: "opacity 0.3s ease-in-out",
  };

  return (
    <div className="bg-blue-200  h-fit rounded-xl  rounded-br-full  rounded-tl-full rounded-bl-full mb-16 bg-opacity-30">
      <div className="grid grid-cols-3 h-full content-center" style={style}>
        <div className=" col-span-1 bg-blue-200 rounded-full bg-opacity-20">
          <img
            src={branding}
            alt="app name and logo"
            className=" w-[80%] scale-x-110  h-[100%] translate-x-[30%] rounded-bl-[105px]"
          ></img>
        </div>
        <div className="grid justify-items-center content-center col-span-2 mb-[10%]">
          <h1 className=" pt-8  w-fit text-white font-displaySemibold text-5xl">
            Seamless Branding Integration <br />
            <span className=" text-2xl italic">
              Unify your brand, elevate your app.
            </span>
          </h1>
          <div className="w-[90%] ">
            <p className="px-10 mx-5 pt-4 w-fit text-white font-displayRegular text-2xl text-start">
              From colors to fonts, we seamlessly integrate your branding at
              every touchpoint, crafting a cohesive and unforgettable
              experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Feed = (props) => {
  const style = {
    opacity: props.isVisible ? "1" : "0.5",
    transition: "opacity 0.3s ease-in-out",
  };

  return (
    <div
      style={style}
      className="bg-blue-200  h-fit rounded-xl  rounded-br-full  rounded-tr-full rounded-bl-full mb-16 bg-opacity-30"
    >
      <div className="grid grid-cols-3 h-full content-center">
        <div className="grid col-span-2 justify-items-center content-center mb-[10%]">
          <h1 className="px-8 pt-8 mx-4 w-fit  text-white font-displaySemibold text-5xl">
            Projects Listing
            <br />
            <span className=" text-2xl italic">
              Boost Engagement with a Dynamic Project Showcase
            </span>
          </h1>
          <div className="grid justify-items-center ml-4">
            <p className="px-10  mx-5 pt-4 text-wrap w-[80%] text-white font-displayRegular text-2xl">
              Keep stakeholders informed and engaged with your latest properties
              and projects through the dynamic App Feed. Seamlessly share
              updates, photos, and key details directly to their fingertips,
              fostering excitement and boosting interest.
            </p>
          </div>
        </div>
        <div className=" col-span-1 bg-blue-200 bg-opacity-10 rounded-full">
          <img
            src={appfeed}
            alt="app name and logo"
            className=" w-[60%] scale-x-[115%] scale-y-110  h-[100%] translate-x-[-25%]"
          ></img>
        </div>
      </div>
    </div>
  );
};

const Investments = (props) => {
  const style = {
    opacity: props.isVisible ? "1" : "0.5",
    transition: "opacity 0.3s ease-in-out",
  };

  return (
    <div
      className="bg-blue-200  h-fit rounded-xl  rounded-br-full  rounded-tl-full rounded-bl-full mb-16 bg-opacity-30"
      style={style}
    >
      <div className="grid grid-cols-3 h-full justify-items-center content-center">
        <div className=" col-span-1  bg-blue-200 bg-opacity-10 rounded-full">
          <img
            src={myProjectsImage}
            alt="app name and logo"
            className=" w-[60%] scale-x-[115%] scale-y-110  h-[100%] translate-x-[75%]"
          ></img>
        </div>
        <div className="grid col-span-2 justify-items-center content-center mb-[10%]">
          <h1 className="px-8 pt-8 mx-8 w-fit text-white font-displaySemibold text-5xl">
            My Projects <br />
            <span className=" text-2xl italic">
              Empower Your Users with Investment Transparency
            </span>
          </h1>
          <div className=" ml-[10%]">
            <p className="px-10 mx-5 pt-4 text-wrap  text-white font-displayRegular text-2xl text-start">
              Elevate user experience with Investment Central, a comprehensive
              investment tracking and management solution. Build trust and
              loyalty by offering clear insights through detailed reports,
              seamless installment management, and intuitive navigation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const InAppChat = (props) => {
  const style = {
    opacity: props.isVisible ? "1" : "0.6",
    transition: "opacity 0.3s ease-in-out",
  };

  return (
    <div
      style={style}
      className="bg-blue-200 h-fit  rounded-br-full  rounded-tr-full rounded-bl-full mb-16 bg-opacity-30"
    >
      <div className="grid grid-cols-3 h-full content-center">
        <div className="grid col-span-2 justify-items-center content-center mb-[10%]">
          <h1 className="px-8 pt-8 mx-4 w-fit  text-white font-displaySemibold text-5xl">
            Live Chat <br />
            <span className=" text-2xl italic">
              Offer support and convert Leads with In-App Live Chat
            </span>
          </h1>
          <div className="grid justify-items-center ml-4">
            <p className="px-10  mx-5 pt-4 text-wrap w-[80%] text-white font-displayRegular text-2xl">
              Foster meaningful connections and drive investment conversions
              with seamless in-app Live Chat. Empower your team to engage users
              in real-time, address inquiries efficiently, and seamlessly
              transition them from interested parties to confident investors.
            </p>
          </div>
        </div>
        <div className=" col-span-1 pt-8  bg-blue-200 bg-opacity-10 translate-x-5 rounded-full">
          <img
            src={appchat}
            alt="app name and logo"
            className=" w-[80%] h-[100%] translate-x-[-25%]"
          ></img>
        </div>
      </div>
    </div>
  );
};
