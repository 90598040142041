import React from "react";
import appstore from "../assets/appstore.svg";
import googleplay from "../assets/googleplay.svg";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTreeCity } from "react-icons/fa6";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="grid grid-rows-12 bg-footerdark  h-[60vh]  px-4">
      <div className="grid row-span-8 grid-cols-5  justify-items-center content-center">
        <div>
          <div className="flex items-start">
            {/* <img src={logo} alt="logo" className="w-14 h-10 " /> */}
            <FaTreeCity style={{ color: "white", fontSize: "3rem" }} />
            <h1 className="text-4xl font-bold text-white px-2  mt-3 font-displayBold">
              NewUrban
            </h1>
          </div>
          <div className="flex space-x-4 justify-start py-4">
            <FaFacebookSquare className="  size-10 text-white p-2 border border-white rounded-lg cursor-pointer transition-all duration-400 hover:bg-btnorange" />
            <FaLinkedin className=" size-10 text-white p-2 border border-white rounded-lg cursor-pointer transition-all duration-400 hover:bg-btnorange" />
            <FaInstagramSquare className="  size-10 text-white p-2 border border-white rounded-lg cursor-pointer transition-all duration-400 hover:bg-btnorange" />
          </div>
          <div className="flex space-x-2">
            <img
              src={appstore}
              alt="appstore"
              className="w-[120px] p-2 border border-white  rounded-xl  cursor-pointer transition-all duration-400 hover:bg-btnorange"
            ></img>
            <img
              src={googleplay}
              alt="googleplay"
              className="w-[120px] p-2 border border-white rounded-xl cursor-pointer transition-all duration-400 hover:bg-btnorange"
            ></img>
          </div>
        </div>
        <div>
          <h1 className="text-white text-xl font-displaySemibold pb-4 mt-4">
            Product
          </h1>
          <ul className="text-white font-displayRegular pl-2 space-y-2">
            <ListItem title="How it works?" link="/product" />
            <ListItem title="Features" link="/" />
          </ul>
        </div>
        <div>
          <h1 className="text-white text-xl font-displaySemibold pb-4 mt-4">
            About
          </h1>
          <ul className="text-white font-displayRegular pl-2 space-y-2">
            <ListItem title="About us" link="/about" />
            <ListItem title="Contanct us" link="/about" />
            <ListItem title="Support" link="/about" />
          </ul>
        </div>
        <div></div>
        <div className="grid justify-items-center content-center space-y-4">
          <h1 className="text-3xl font-bold text-white px-1 font-displaySemibold text-center">
            Get your own <br />
            real estate App
          </h1>
          <Link
            to="/request"
            class="relative flex h-[50px] w-40 items-center justify-center overflow-hidden bg-btnorange   rounded-md font-displaySemibold text-white shadow-2xl transition-all duration-300 before:absolute before:inset-0 before:border-0 before:border-white before:duration-100 before:ease-linear hover:bg-white hover:text-blue-600 hover:shadow-blue-600 hover:before:border-[25px]"
          >
            <span class="relative z-10">Request a demo</span>
          </Link>
        </div>
      </div>
      <div className="row-span-2"></div>
      <div className="row-span-2">
        <hr
          style={{
            background: "gray",
            color: "gray",
            borderColor: "gray",
            height: "1px",
          }}
        />
        <div className="flex justify-between pt-8  text-gray-400 font-displayRegular text-sm">
          <div className="">
            <p>2023 NewUrban all rights reserved.</p>
          </div>

          <div className="">
            <p>Terms | Privacy</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

const ListItem = ({ title = "", link = "" }) => {
  return (
    <li className=" relative group cursor-pointer">
      <Link to={link}>
        <span>{title}</span>
        <span className="absolute -bottom-1 left-1/2 w-0 h-[1px] bg-white group-hover:w-1/2 group-hover:transition-all"></span>
        <span className="absolute -bottom-1 right-1/2 w-0 h-[1px] bg-white group-hover:w-1/2 group-hover:transition-all"></span>
      </Link>
    </li>
  );
};
