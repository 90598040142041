import React from "react";
import {
  format,
  eachDayOfInterval,
  getDay,
  endOfWeek,
  startOfWeek,
  isPast,
  isToday,
} from "date-fns";

const Calendar = ({
  selectedDate,
  setSelectedDate,
  selectedTime,
  setSelectedTime,
  buttonClicked,
}) => {
  const currentDate = new Date();

  const startOfTheWeek = startOfWeek(currentDate);
  const endOfTheWeek = endOfWeek(currentDate);
  const startOfNextWeek = new Date(
    endOfTheWeek.getFullYear(),
    endOfTheWeek.getMonth(),
    endOfTheWeek.getDate() + 1
  );
  const endOfTwoWeeks = new Date(
    startOfNextWeek.getFullYear(),
    startOfNextWeek.getMonth(),
    startOfNextWeek.getDate() + 6
  );

  const daysOfWeek = eachDayOfInterval({
    start: startOfTheWeek,
    end: endOfTheWeek,
  });

  const daysOfNextWeek = eachDayOfInterval({
    start: startOfNextWeek,
    end: endOfTwoWeeks,
  });

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const dateAsString = currentDate.toString();
  const timezone = dateAsString.match(/\(([^\)]+)\)$/)[1];

  const abbreviations = {
    "Eastern Daylight Time": "EDT",
    "Eastern European Standard Time": "EET",
    "Central European Time": "CET",
    "Central Daylight Time": "CDT",
    "Pacific Standard Time": "PST",
    "Mountain Standard Time": "MST",
    "Greenwich Mean Time": "GMT",
    "Coordinated Universal Time": "UTC",
    "Australian Eastern Standard Time": "AEST",
    "Indian Standard Time": "IST",
  };

  const toTZAbbreviation = (timezone) => abbreviations[timezone];

  const availableTime = [
    "1 : 00 pm",
    "2 : 00 pm",
    "3 : 00 pm",
    "4 : 00 pm",
    "5 : 00 pm",
    "6 : 00 pm",
    "7 : 00 pm",
  ];

  const handleDateClick = (day) => {
    setSelectedDate(day);
    setSelectedTime(null);
    console.log(format(day, "E d LLL y"));
  };
  const handleTimeClick = (time) => {
    setSelectedTime(time);
    console.log(time);
  };
  return (
    <div className={`grid grid-rows-12 p-2`}>
      <div className="grid grid-cols-5 row-span-11 gap-2">
        <div
          className={`"bg-white p-4 " ${
            selectedDate == null
              ? "col-span-5 px-24 p-4"
              : "col-span-3 px-0 p-4"
          }`}
        >
          <h1 className="text-xl font-displaySemibold mb-4 ">
            {format(currentDate, "MMMM yyyy")}{" "}
            <span className="font-displayRegular text-lg font-normal">
              <br /> ( Select a Date )
            </span>
            {buttonClicked && selectedDate == null ? (
              <div className=" absolute animate-appear text-red-500 text-sm font-displayRegular">
                <p>field must be filled.</p>
              </div>
            ) : (
              <div></div>
            )}
          </h1>
          <div className="grid grid-cols-7 gap-2">
            {daysOfWeek.map((day) => (
              <div
                key={day.getTime()}
                className="text-sm font-displayRegular p-2 text-center"
              >
                <div>{dayNames[getDay(day)]}</div>
              </div>
            ))}
            {daysOfWeek.map((day) => {
              if (isPast(day) && !isToday(day)) {
                return (
                  <div
                    key={day.getTime()}
                    className={`bg-gray-400 rounded-lg  font-displayRegular p-2 text-center`}
                  >
                    <div>{format(day, "d")}</div>
                  </div>
                );
              } else if (isToday(day)) {
                return (
                  <div
                    key={day.getTime()}
                    className={`${
                      selectedDate && selectedDate.getTime() === day.getTime()
                        ? "bg-btnorange text-white"
                        : "bg-gray-200 text-black"
                    } rounded-lg transition-all duration-200 ease-in-out border border-blue-500 hover:bg-btnorange cursor-pointer font-displayRegular p-2 text-center`}
                    onClick={() => handleDateClick(day)}
                  >
                    <div>{format(day, "d")}</div>
                  </div>
                );
              } else {
                return (
                  <div
                    key={day.getTime()}
                    className={`${
                      selectedDate && selectedDate.getTime() === day.getTime()
                        ? "bg-btnorange text-white"
                        : "bg-gray-200 text-black"
                    } rounded-lg transition-all duration-200 ease-in-out hover:bg-btnorange cursor-pointer font-displayRegular p-2 text-center`}
                    onClick={() => handleDateClick(day)}
                  >
                    <div>{format(day, "d")}</div>
                  </div>
                );
              }
            })}
            {daysOfNextWeek.map((day) => (
              <div
                key={day.getTime()}
                className={`${
                  selectedDate && selectedDate.getTime() === day.getTime()
                    ? "bg-btnorange text-white"
                    : "bg-gray-200 text-black"
                } rounded-lg transition-all duration-200 ease-in-out hover:bg-btnorange cursor-pointer font-displayRegular p-2 text-center`}
                onClick={() => handleDateClick(day)}
              >
                <div>{format(day, "d")}</div>
              </div>
            ))}
          </div>
        </div>
        {selectedDate && (
          <div className={` animate-appear p-4  col-span-2 text-start`}>
            <h1 className=" text-xl font-displaySemibold">
              {selectedDate == null
                ? ""
                : isToday(selectedDate)
                ? "Today"
                : format(selectedDate, "E d LLL y")}
              <span className="font-displayRegular text-lg font-normal">
                <br /> ( Select a Time ) - {toTZAbbreviation(timezone)}
              </span>
              {buttonClicked && selectedTime == null ? (
                <div className=" absolute animate-appear text-red-500 text-sm font-displayRegular">
                  <p>field must be filled.</p>
                </div>
              ) : (
                <div></div>
              )}
            </h1>
            <div className="grid grid-cols-2 pt-4">
              {availableTime.map((time) => (
                <div
                  className={`${
                    selectedTime === time
                      ? "bg-btnorange text-white"
                      : "bg-gray-200 text-black"
                  } m-2 rounded-lg transition-all duration-200 ease-in-out hover:bg-btnorange cursor-pointer font-displayRegular p-2 text-center`}
                  onClick={() => handleTimeClick(time)}
                >
                  {time}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {selectedTime && (
        <div className={`row-span-1 animate-appear`}>
          <h1 className=" text-xl text-center">
            Schedule meeting{" "}
            {isToday(selectedDate) ? (
              <span className=" font-displaySemibold">
                Today
                {" , "}
                {selectedTime == null ? "" : selectedTime}{" "}
              </span>
            ) : (
              <span className=" font-displaySemibold">
                {" at "}
                {selectedDate == null ? "" : format(selectedDate, "E d LLL y")}
                {" , "}
                {selectedTime == null ? "" : selectedTime}{" "}
              </span>
            )}
          </h1>
        </div>
      )}
    </div>
  );
};

export default Calendar;
