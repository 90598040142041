import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/Home";
import Product from "./components/Product";
import About from "./components/About";
import Request from "./components/Request";
import Footer from "./components/Footer";
import mySvg from "./assets/backSvg.svg";
import backBottom from "./assets/backBottom.svg";
import ScrollToTop from "./other/ScrollToTop";

const App = () => {
  return (
    <div
      id="main-div"
      className="h-full bg-backgroundblue"
      style={{
        backgroundImage: `url(${mySvg})`,
        backgroundRepeat: "no-repeat",
        // backgroundPosition: "top",
        backgroundPositionX: "center", // or "left"
        backgroundPositionY: "-200px  ",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${backBottom})`,
          backgroundRepeat: "no-repeat",
          // backgroundPosition: "top",
          backgroundPositionX: "center", // or "left"
          backgroundPositionY: "2950px  ",
        }}
      >
        <Router>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product" element={<Product />} />
            <Route path="/about" element={<About />} />
            <Route path="/request" element={<Request />} />
          </Routes>
          <Footer />
        </Router>
      </div>
    </div>
  );
};

export default App;
