import React from "react";
import nameav from "../assets/name-av.png";
import designyourapp from "../assets/designyourapp.png";
import configureyourapp from "../assets/configureyourapp.png";
import launchapp from "../assets/launchapp.png";
import live from "../assets/live.png";
import appstore from "../assets/appstore.svg";
import googleplay from "../assets/googleplay.svg";
import { FaArrowDown } from "react-icons/fa";
import { useRef } from "react";
import TrackVisibility from "react-on-screen";

const Product = () => {
  const Section1 = useRef(null);
  const Section2 = useRef(null);
  const Section3 = useRef(null);
  const Section4 = useRef(null);
  const finish = useRef(null);
  const scrollTo1 = () =>
    Section1.current.scrollIntoView({ behavior: "smooth", block: "center" });
  const scrollTo2 = () =>
    Section2.current.scrollIntoView({ behavior: "smooth", block: "center" });
  const scrollTo3 = () =>
    Section3.current.scrollIntoView({ behavior: "smooth", block: "center" });
  const scrollTo4 = () =>
    Section4.current.scrollIntoView({ behavior: "smooth", block: "center" });
  const scrollToFinish = () =>
    finish.current.scrollIntoView({ behavior: "smooth", block: "center" });

  return (
    <div className="grid justify-items-center px-[150px]">
      <div className="container grid grid-cols-1 pt-24 mt-28 z-1 content-center justify-items-center">
        <div className="grid text-white content-center justify-items-center col-span-2 ">
          <span className=" font-displaySemibold text-7xl text-center leading-[100px]">
            Get your <span className="">App off the ground</span>
            <br />
            in just a few steps
          </span>
          <p className="font-displayRegular py-8 text-2xl text-center leading-[50px] w-[80%]">
            From choosing a name until officially launching, our team will be
            present to ensure the entire process is smooth and straightforward.
          </p>
          <div className="py-10">
            <TrackVisibility>
              <ScrollButton scrollTo={scrollTo1} />
            </TrackVisibility>
          </div>
        </div>
      </div>
      {/* ///////////////// Section 1 /////////////////////// */}
      <div className="py-24" ref={Section1}>
        <div className="grid grid-cols-6 content-center justify-items-center">
          <div className="grid col-span-1">
            <div className="grid w-24 h-24 rounded-full bg-btnorange drop-shadow-lg content-center justify-items-center">
              <h1 className="text-white text-6xl font-displaySemibold pr-1">
                1
              </h1>
            </div>
          </div>
          <div className="grid col-span-3 pt-3">
            <h1 className="text-white text-5xl font-displaySemibold">
              Name your app
            </h1>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              During your product demonstration, we’ll collaborate to select an
              app name that aligns perfectly with your business.
            </p>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              The name of your app could be your brokerage name, your team name,
              or even a catchy tagline or phrase.
            </p>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              It’s important to remember that apps are akin to domain names.
              They must be unique and available for use in the App Store.
            </p>
          </div>
          <div className="grid col-span-2 content-center">
            <img
              src={nameav}
              alt="img"
              className=" scale-[135%] rounded-full  border border-white drop-shadow-md"
            ></img>
          </div>
        </div>
        <div className="grid justify-items-center py-10 w-full">
          <TrackVisibility>
            <ScrollButton scrollTo={scrollTo2} />
          </TrackVisibility>
        </div>
      </div>
      {/* ///////////////// Section 2 /////////////////////// */}
      <div className="py-24 pt-40" ref={Section2}>
        <div className="grid grid-cols-6 content-center justify-items-center">
          <div className="grid col-span-3 pt-3">
            <h1 className="text-white text-5xl font-displaySemibold">
              Design your app
            </h1>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              We'll customize your app's design according to your branding
              preferences. You can select your custom colors, and fonts.
            </p>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              You can use your company logo as your app icon image, so that it
              reflects your brand identity and appeals to your target audience
              in the app store and on their devices.
            </p>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              We'll guide you through the process of publishing your app on
              Apple and Google platforms, and explain how your app's details
              will be shown to the public.
            </p>
          </div>
          <div className="grid col-span-2 content-center">
            <img
              src={designyourapp}
              alt="img"
              className=" scale-[135%] rounded-full  border border-white drop-shadow-md"
            ></img>
          </div>
          <div className="grid col-span-1">
            <div className="grid w-24 h-24 rounded-full bg-btnorange drop-shadow-lg content-center justify-items-center">
              <h1 className="text-white text-6xl font-displaySemibold">2</h1>
            </div>
          </div>
        </div>
        <div className="grid justify-items-center py-10 w-full">
          <TrackVisibility>
            <ScrollButton scrollTo={scrollTo3} />
          </TrackVisibility>
        </div>
      </div>
      {/* ///////////////// Section 3 /////////////////////// */}
      <div className="py-24" ref={Section3}>
        <div className="grid grid-cols-6 content-center justify-items-center">
          <div className="grid col-span-1">
            <div className="grid w-24 h-24 rounded-full bg-btnorange drop-shadow-lg content-center justify-items-center">
              <h1 className="text-white text-6xl font-displaySemibold pr-1">
                3
              </h1>
            </div>
          </div>
          <div className="grid col-span-3 pt-3">
            <h1 className="text-white text-5xl font-displaySemibold">
              Configure your app
            </h1>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              You can choose the tabs that suit your app's functionality.
            </p>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              We'll help you to configure your backend, upload your project
              files, and monitor your customer payments.
            </p>
          </div>
          <div className="grid col-span-2 pt-3">
            <img
              src={configureyourapp}
              alt="img"
              className=" scale-[135%] rounded-full  border border-white drop-shadow-md"
            ></img>
          </div>
        </div>
        <div className="grid justify-items-center py-10 w-full">
          <TrackVisibility>
            <ScrollButton scrollTo={scrollTo4} />
          </TrackVisibility>
        </div>
      </div>
      {/* ///////////////// Section 4 /////////////////////// */}
      <div className="py-24" ref={Section4}>
        <div className="grid grid-cols-6 content-center justify-items-center">
          <div className="grid col-span-3 pt-3">
            <h1 className="text-white text-5xl font-displaySemibold">
              launch to the world
            </h1>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              As soon as your app is available on the Apple App Store and Google
              Play, we'll notify you right away.
            </p>
            <p className="text-white font-displayRegular text-xl w-[80%] p-4">
              We offer continuous live training and support to help you stay on
              top of all the new features and use the best practices.
            </p>
          </div>
          <div className="grid col-span-2 pt-3">
            <img
              src={launchapp}
              alt="img"
              className=" scale-[135%] rounded-full  border border-white drop-shadow-md"
            ></img>
          </div>
          <div className="grid col-span-1">
            <div className="grid w-24 h-24 rounded-full bg-btnorange drop-shadow-lg content-center justify-items-center">
              <h1 className="text-white text-6xl font-displaySemibold">4</h1>
            </div>
          </div>
        </div>
        <div className="grid justify-items-center py-10 w-full">
          <TrackVisibility>
            <ScrollButton scrollTo={scrollToFinish} />
          </TrackVisibility>
        </div>
      </div>
      <div className="py-48 bg-backgroundblue rounded-full" ref={finish}>
        <div className="flex z-1 ">
          <div className="grid text-white content-start  ">
            <span className=" font-displaySemibold text-7xl pt-24">
              Congratulations, <br /> you're <span className="">live!</span>
            </span>
            <div className="flex">
              <img
                src={appstore}
                alt="appstore"
                className="m-4 p-2 border border-white  rounded-xl  cursor-pointer transition-all duration-400 hover:bg-btnorange"
              ></img>
              <img
                src={googleplay}
                alt="googleplay"
                className=" m-4 p-2 border border-white rounded-xl cursor-pointer transition-all duration-400 hover:bg-btnorange"
              ></img>
            </div>
          </div>
          <div className="grid  scale-125 content-start  justify-items-center ">
            <img
              src={live}
              alt="logo"
              className="drop-shadow-2xl   animate-bouncy"
              unselectable="on"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;

const ScrollButton = (props) => {
  const style = {
    opacity: props.isVisible ? "1" : "0",
    transition: "opacity 0.5s ease-in-out",
  };

  return (
    <button
      style={style}
      onClick={props.scrollTo}
      className=" w-24 h-24 rounded-full bg-btnorange drop-shadow-lg animate-bouncingarrow text-4xl items-center justify-center overflow-hidden font-displaySemibold text-white shadow-2xl transition-all duration-300 before:absolute before:inset-0 before:border-0 before:border-white before:duration-100 before:ease-linear hover:bg-white hover:text-blue-600 hover:shadow-blue-600 hover:before:border-[0]"
    >
      <FaArrowDown className="w-full h-full p-4" />
    </button>
  );
};
