import react, { useState } from "react";
import { Carousel, Typography, Button } from "@material-tailwind/react";
import carousel1 from "../../assets/carousel-1.png";
import carousel2 from "../../assets/carousel-2.png";
import carousel3 from "../../assets/carousel-3.png";
import magnified from "../../assets/magnified.png";

export default function CarouselDefault() {
  return (
    <Carousel
      loop={true}
      className="rounded-3xl h-[100%] border border-white z-0"
      navigation={({ setActiveIndex, activeIndex, length }) => (
        <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
          {new Array(length).fill("").map((_, i) => (
            <span
              key={i}
              className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
              }`}
              onClick={() => setActiveIndex(i)}
            />
          ))}
        </div>
      )}
    >
      <div className="relative h-full w-full">
        <img
          src={carousel1}
          alt="image 1"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-start content-center px-24 ">
          <div className="w-[80%] text-start md:w-[60%]">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-2xl lg:text-5xl font-displaySemibold"
            >
              Post your projects
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 md:text-xl sm:text-xs font-displayMid"
            >
              Keep stakeholders informed and engaged with your latest properties
              and projects through the dynamic App Feed. Seamlessly share
              updates, photos, and key details directly to their fingertips,
              fostering excitement and boosting interest.
            </Typography>
            <div className="flex justify-center gap-2">
              <Button
                size="lg"
                color="white"
                variant="text"
                className=" bg-btnorange font-displayMid"
              >
                Request a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-full w-full">
        <img
          src={magnified}
          alt="image 1"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-end content-center px-24 ">
          <div className="w-[80%] text-start md:w-[60%]">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-2xl lg:text-5xl font-displaySemibold"
            >
              User Engagement: Expressing Interest in Your Projects
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 md:text-xl sm:text-xs font-displayMid"
            >
              With your projects showcase, users gain the ability to explore a
              comprehensive list of available projects and express their
              interest. By leveraging this feature, you can effortlessly
              identify potential clients and gauge their enthusiasm for your
              offerings.
            </Typography>
            <div className="flex justify-center gap-2">
              <Button
                size="lg"
                color="white"
                variant="text"
                className=" bg-btnorange font-displayMid"
              >
                Request a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-full w-full">
        <img
          src={carousel3}
          alt="image 1"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-start content-center px-24 ">
          <div className="w-[80%] text-start md:w-[60%]">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-2xl lg:text-5xl font-displaySemibold"
            >
              Live Chat: Real-time Support and Closing Deals
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 md:text-xl sm:text-xs font-displayMid"
            >
              in-app live chat feature, enabling you to promptly address user
              inquiries and efficiently finalize deals. Our dynamic live chat
              system facilitates real-time communication, allowing you to
              provide immediate responses, personalized assistance, and
              ultimately secure successful deals.
            </Typography>
            <div className="flex justify-center gap-2">
              <Button
                size="lg"
                color="white"
                variant="text"
                className=" bg-btnorange font-displayMid"
              >
                Request a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="relative h-full w-full">
        <img
          src={carousel2}
          alt="image 1"
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 grid h-full w-full place-items-end content-center px-24 ">
          <div className="w-[80%] text-start md:w-[60%]">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-2xl lg:text-5xl font-displaySemibold"
            >
              Clients can track installments and projects
            </Typography>
            <Typography
              variant="lead"
              color="white"
              className="mb-12 opacity-80 md:text-xl sm:text-xs font-displayMid"
            >
              Elevate user experience with Investment Central, a comprehensive
              investment tracking and management solution. Build trust and
              loyalty by offering clear insights through detailed reports,
              seamless installment management, and intuitive navigation.
            </Typography>
            <div className="flex justify-center gap-2">
              <Button
                size="lg"
                color="white"
                variant="text"
                className=" bg-btnorange font-displayMid"
              >
                Request a demo
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
}
