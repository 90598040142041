import React from "react";
import { Link } from "react-router-dom";

import { FaTreeCity } from "react-icons/fa6";
import { useState, useEffect } from "react";
import "./Styles/Header.css";

const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;

    if (
      currentScrollPos > prevScrollPos &&
      currentScrollPos > 0 &&
      currentScrollPos > 50
    ) {
      setVisible(false);
    } else {
      setVisible(true);
    }

    setPrevScrollPos(currentScrollPos);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    <header
      className={`fixed z-10 top-0 w-full transition-all duration-300 ${
        visible ? "translate-y-0" : "-translate-y-full"
      } ${visible && prevScrollPos > 60 ? "bg-backgroundblue shadow-md" : ""}`}
    >
      <nav className="flex mx-auto w-full h-24 justify-between items-center px-4">
        <div className="flex items-center space-x-16">
          <Link to="/" className="flex items-center">
            {/*             <img src={logo} alt="logo" className="w-10 h-6 " />
             */}{" "}
            <FaTreeCity style={{ color: "white", fontSize: "3rem" }} />
            <h1 className="text-4xl font-bold text-white px-2 mt-3 font-displayBold">
              NewUrban
            </h1>
          </Link>

          <ul className="flex space-x-24 pt-2 font-displayRegular">
            <li>
              <Link to="/" className="text-white">
                <p className=" relative group">
                  <span>Home</span>
                  <span className="absolute -bottom-1 left-1/2 w-0 h-1 bg-white group-hover:w-1/2 group-hover:transition-all"></span>
                  <span className="absolute -bottom-1 right-1/2 w-0 h-1 bg-white group-hover:w-1/2 group-hover:transition-all"></span>
                </p>
              </Link>
            </li>
            <li>
              <Link to="/product" className="text-white ">
                <p className=" relative group">
                  <span>How it works?</span>
                  <span className="absolute -bottom-1 left-1/2 w-0 h-1 bg-white group-hover:w-1/2 group-hover:transition-all"></span>
                  <span className="absolute -bottom-1 right-1/2 w-0 h-1 bg-white group-hover:w-1/2 group-hover:transition-all"></span>
                </p>
              </Link>
            </li>
            <li>
              <Link to="/about" className="text-white">
                <p className=" relative group">
                  <span>About us</span>
                  <span className="absolute -bottom-1 left-1/2 w-0 h-1 bg-white group-hover:w-1/2 group-hover:transition-all"></span>
                  <span className="absolute -bottom-1 right-1/2 w-0 h-1 bg-white group-hover:w-1/2 group-hover:transition-all"></span>
                </p>
              </Link>
            </li>
          </ul>
        </div>

        <Link
          to="/request"
          class="relative flex h-[50px] w-40 items-center justify-center overflow-hidden bg-btnorange   rounded-md font-displaySemibold text-white shadow-2xl transition-all duration-300 before:absolute before:inset-0 before:border-0 before:border-white before:duration-100 before:ease-linear hover:bg-white hover:text-blue-600 hover:shadow-blue-600 hover:before:border-[25px]"
        >
          <span class="relative z-10">Request a demo</span>
        </Link>
      </nav>
    </header>
  );
};

export default Header;
