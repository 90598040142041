import React from "react";
import { useState } from "react";
import Calendar from "./SubComp/Calendar";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const Request = () => {
  return (
    <div className="grid justify-items-center">
      <div className="container grid grid-cols-5 px-[40px] pt-48 mt-6 z-1 content-center justify-items-center ">
        <div className="grid text-white content-start col-span-2 w-[80%] pt-4 ">
          <span className=" font-displaySemibold text-5xl leading-[80px]">
            Request a demo
          </span>
          <p className="font-displayRegular py-4 text-lg leading-[30px] w-[100%]">
            Schedule a personalized demo with one of our App Specialists. During
            the session, we’ll guide you through a live demonstration of the
            app, discuss pricing details, and address any inquiries you might
            have.
          </p>
        </div>
        <div className="grid col-span-3 w-full  content-start justify-items-center">
          <ExampleForm />
        </div>
      </div>

      <div className="p p-32"></div>
    </div>
  );
};

export default Request;

const ExampleForm = () => {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const [buttonClicked, setButtonClicked] = useState(false);

  const SendData = () => {
    if (dataMissing) {
      setButtonClicked(true);
    } else {
      console.log(firstName, lastName);
      console.log(email);
      console.log(phone);
      console.log(selectedDate, selectedTime);
    }
  };

  function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }

  const dataMissing =
    isEmptyOrSpaces(firstName) ||
    isEmptyOrSpaces(lastName) ||
    isEmptyOrSpaces(email) ||
    phone.length < 5 ||
    selectedTime == null ||
    selectedTime == null;
  return (
    <div className="bg-white p-8 border-backgroundblue border-8 rounded-2xl font-displayRegular w-full">
      <div>
        <h1 className=" font-displaySemibold text-3xl pb-4 text-start">
          Please fill the information below
        </h1>
      </div>
      <div className="flex mb-4 px-32">
        <div className="w-1/2 ">
          <label
            htmlFor="first-name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            First Name
          </label>

          <input
            type="text"
            id="first-name"
            onChange={(e) => setfirstName(e.target.value.replace(/\s/g, ""))}
            value={firstName}
            className="w-full px-3 py-2 bg-gray-300 text-sm  text-black rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {buttonClicked && isEmptyOrSpaces(firstName) ? (
            <div className=" absolute animate-appear text-red-500 text-sm font-displayRegular">
              <p>field must be filled.</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="w-1/2 ml-2">
          <label
            htmlFor="last-name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Last Name
          </label>
          <input
            type="text"
            id="last-name"
            onChange={(e) => setlastName(e.target.value.replace(/\s/g, ""))}
            value={lastName}
            className="w-full px-3 py-2 bg-gray-300 text-sm text-black rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
          {buttonClicked && isEmptyOrSpaces(lastName) ? (
            <div className=" absolute animate-appear text-red-500 text-sm font-displayRegular">
              <p>field must be filled.</p>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className="mb-4 px-32  pt-2">
        <label
          htmlFor="work-email"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Work Email
        </label>
        <input
          type="text"
          id="work-email"
          onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
          value={email}
          className="w-full px-3 py-2 bg-gray-300 text-sm  text-black  rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        />
        {buttonClicked && isEmptyOrSpaces(email) ? (
          <div className=" absolute  animate-appear text-red-500 text-sm font-displayRegular">
            <p>field must be filled.</p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="mb-4 px-32 pt-2">
        <label
          htmlFor="work-email"
          className="block text-gray-700 text-sm font-bold mb-2"
        >
          Phone Number
        </label>
        {/* <input
          type="phone"
          id="phone"
          className="w-full px-3 py-2 bg-gray-300 text-sm  text-black  rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
        /> */}
        <CustomPhoneInput value={phone} onChange={setPhone} />
        {buttonClicked && phone.length < 5 ? (
          <div className=" absolute animate-appear text-red-500 text-sm font-displayRegular">
            <p>field must be filled.</p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Calendar
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        buttonClicked={buttonClicked}
      />
      <div className="grid justify-items-center z-1">
        <button
          // disabled={dataMissing}
          class="relative flex h-[50px] w-40 items-center justify-center overflow-hidden bg-btnorange   rounded-md font-displaySemibold text-white shadow-lg transition-all duration-300 before:absolute before:inset-0 before:border-0 before:border-white before:duration-100 before:ease-linear hover:bg-white hover:text-blue-600 hover:shadow-blue-200 hover:border-2 hover:border-blue-600 hover:before:border-[25px]"
          onClick={SendData}
        >
          <span class="relative z-1">Schedule</span>
        </button>
      </div>
    </div>
  );
};

const CustomPhoneInput = ({ value, onChange }) => {
  return (
    <div className="custom-input rounded bggra ">
      <PhoneInput
        defaultCountry="eg"
        value={value}
        onChange={onChange}
        inputProps={{
          className:
            " w-full px-3 py-2 bg-gray-300 text-sm  text-black  focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-br-md rounded-tr-md",
        }}
        style={{
          "--react-international-phone-height": "40px",
          "--react-international-phone-background-color": "rgb(229, 231, 235)",
          "--react-international-phone-border-radius": "6px",
          "--react-international-phone-outline": "none",
        }}
      />
    </div>
  );
};
